@tailwind base;
@tailwind components;
@tailwind utilities;

/* Barra de rolagem principal */
::-webkit-scrollbar {
  width: 0px;
}

/* Alça (thumb) da barra de rolagem */
::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
}

/* Rastreamento (track) da barra de rolagem */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}


html {
    height: 100%;
    margin: 0;
}

body {
  height: 100%;
  background-color: #3C3A3B;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active {
  color: #5CE1E6
}

